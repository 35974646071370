import { ModuleWithProviders, NgModule } from '@angular/core';
import { NotificationsComponent } from './notifications.component';
import { CommonModule } from '@angular/common';
import { NOTIFICATIONS_CONFIG, NotificationsConfig } from './notifications-config';

@NgModule({
  declarations: [NotificationsComponent],
  imports: [CommonModule],
  exports: [NotificationsComponent],
})
export class NotificationsModule {
  static forRoot(config: NotificationsConfig): ModuleWithProviders<NotificationsModule> {
    return {
      ngModule: NotificationsModule,
      providers: [
        {
          provide: NOTIFICATIONS_CONFIG,
          useValue: config,
        },
      ],
    };
  }
}
