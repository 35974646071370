import { Component } from '@angular/core';
import { NotificationsService } from './notifications.service';
import { NotificationType, Notification } from './notification';

@Component({
  selector: 'notifications',
  template: `
    <div class="container">
      <div
        *ngFor="let n of notificationsService.notifications"
        (click)="removeNotification(n)"
        class="notification"
        [class.success]="n.type === NotificationType.SUCCESS"
        [class.info]="n.type === NotificationType.INFO"
        [class.warning]="n.type === NotificationType.WARNING"
        [class.error]="n.type === NotificationType.ERROR">
        {{ n.message }}
      </div>
    </div>
  `,
  styles: [
    `
      .container {
        left: 20px;
        top: 20px;
        position: fixed;
        z-index: 1000;
        pointer-events: none;
      }

      .notification {
        color: white;
        border-radius: 5px;
        width: fit-content;
        width: -moz-fit-content;

        pointer-events: auto;
        cursor: pointer;

        padding-left: 1.25rem;
        padding-right: 1.25rem;
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;

        margin-bottom: 0.5rem;
      }

      .success {
        background-color: #00c851;
        border: 1px solid #007e33;
      }

      .info {
        background-color: #33b5e5;
        border: 1px solid #0099cc;
      }

      .warning {
        background-color: #ffbb33;
        border: 1px solid #ff8800;
      }

      .error {
        background-color: #ff4444;
        border: 1px solid #cc0000;
      }
    `,
  ],
})
export class NotificationsComponent {
  readonly NotificationType = NotificationType;

  constructor(public notificationsService: NotificationsService) {}

  removeNotification(n: Notification) {
    this.notificationsService.notifications.splice(
      this.notificationsService.notifications.indexOf(n),
      1,
    );
  }
}
