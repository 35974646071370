import { Inject, Injectable } from '@angular/core';
import { Notification, NotificationType } from './notification';
import { NOTIFICATIONS_CONFIG, NotificationsConfig } from './notifications-config';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  notifications: Notification[] = [];

  constructor(@Inject(NOTIFICATIONS_CONFIG) private config: NotificationsConfig) {}

  notify(type: NotificationType, message: string, timeout?: number): void {
    this.notifications.push({ type, message });
    setTimeout(() => this.notifications.shift(), timeout ?? this.config.timeout);
  }
}
