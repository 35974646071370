export enum NotificationType {
  SUCCESS,
  INFO,
  WARNING,
  ERROR,
}

export interface Notification {
  type: NotificationType;
  message: string;
}
